<template>
  <div class="gradient_home d-flex justify-center transitions">
    <div class="main_container">
      <div class="d-flex wrap pb-20">
        <img class="logo" src="/img/static/logo-landing.svg" alt="" />
        <div class="center  green--text font-28 nonwrap">
          <span class="weight-300 ml-60">secur</span>
          <span>Doc</span>
        </div>
      </div>
      <div class="weight-300 font-40 py-10">
        {{ $t("home.hero.title") }}
      </div>
      <div class="weight-300 font-14 py-20 line-height-25">
        {{ $t("home.hero.subtitle1") }} <br />
        <span> {{ $t("home.hero.try") }}</span>
        <router-link to="register" class="weight-600 font-16 white--text">
          {{ $t("home.hero.free") }}</router-link
        >
        <span> {{ $t("home.hero.paticipants") }}</span>
        <span class="weight-600 font-16"> {{ $t("home.hero.no_cred") }}</span>
      </div>
      <div class="box my-15 pb-30">
        <img class="icon_home" src="/img/static/icon-home-pro.png" alt="" />
        <div class="center">
          <div class="green--text font-40">{{ price }} {{ currency }}</div>
          <div class="green--text ml-10">{{ $t("home.hero.roomsMonth") }}</div>
        </div>
        <div class="font-1 pt-10 pb-30">{{ $t("home.hero.upTo") }}</div>
        <a class="link" href="https://cloud.securmeet.com/login">
          {{ $t("home.hero.getStarted") }}
        </a>
      </div>
      <div class="font-14 weight-300 line-height-25 py-20">
        {{ $t("home.hero.body1") }} <br />
        {{ $t("home.hero.body2") }} <br />
        <router-link class="green--text underline" to="Contact">
          {{ $t("home.hero.clickHere") }}
        </router-link>
        {{ $t("home.hero.body3") }}
      </div>
    </div>
    <img class="gradient_image" src="/img/static/group-130.svg" alt="" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    currency() {
      return this.location.currency;
    },
    price() {
      return process.env.VUE_APP_PRICE;
    },
    ...mapState("user", ["location"]),
  },
};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.box {
  box-sizing: border-box;
  width: 230px;
  min-height: 230px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #50575e;
  background-color: hsla(0, 0%, 100%, 0.03);
  text-align: center;
  text-decoration: none;
}

.box:hover {
  border-color: rgba(255, 255, 255, 0.25);
  background-color: rgba(255, 255, 255, 0.05);
}

.logo {
  max-width: 200px;
  margin-left: -3px;
}

.icon_home {
  max-width: 50px;
}

.main_container {
  padding-bottom: 150px;
  padding-top: 150px;
}

.gradient_home {
  position: relative;
  min-height: 100vh;
  height: 100%;
  background-image: var(--gradient);
  z-index: 1;
}

.gradient_image {
  max-width: 1200px;
  min-width: 300px;
  width: 83%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  right: 0;
}

.link {
  margin-top: 30px;
  height: 40px;
  background-color: #151b22;
  font-size: 14px;
  color: white;
  padding: 10px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  outline: none;
}

.link:hover {
  background-color: #425464;
}

.logoDoc{
  max-width: 45px;
}

.main_container {
    padding-top: 100px;
}
</style>
