<template>
  <div class="container center">
    <div class="main_container font-28 center">
     {{$t('faq.homePage.got_any_questions')}}
      <router-link class="ml-10" to="/faq">
        {{$t('faq.homePage.checkout_faq')}}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  background-color: white;
  color: var(--almost-black);
  padding-top: 50px;
  padding-bottom: 60px;
  overflow: hidden;
}

a {
  color: #409875;
}
</style>