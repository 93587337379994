<template>
    <div class="white">
        <div class="main_container center white black--text py-50 w100 row">
            <img src="/img/static/mockDropjpg" class="" alt="">
            <div class="dlAppContainer grow">
                <h1>  {{ $t("home.download.title") }}</h1>
                <span class="weight-300 description">
                    {{ $t("home.download.desc") }}
                 </span>
                <div class="link_container wrap  row mt-20">
                    <a class="playGreen" target="_blank" href="https://play.google.com/store/apps/details?id=me.securcom.meet">
                        <s-icon  class="icon">playstore</s-icon>
                        Google play store
                    </a>
                    <a class="appleBlue" target="_blank" href="https://apps.apple.com/sa/app/securmeet/id1508898635">
                        <s-icon color="white" class="icon">apple</s-icon>
                        App Store
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
a {
    min-width: 95px;
    height: 40px;
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    outline: none;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    gap: 10px;
}

.link_container {
    gap: 20px;
}

.description {
    line-height: 30px;
}

.dlAppContainer {
    /* max-width: 700px; */
}

img {
    max-width: 300px;
    width: 300px;
}

.main_container {
    gap: 100px;
}

.icon {
    width: 25px;
}

.black{
    background-color: black;
}
.black:hover {
  background-color: #202020;
}

.appleBlue{
  background-color: #007AFF  
}

.playGreen{
    background-color: #68BB59   
}

</style>