<template>
  <div>
    <Toolbar/> 
    <Hero />
    <InfoBanner />
    <Description />
    <Plans />
    <FAQ />
    <DownloadApp />
    <Compliant />
    <Footer />
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbars/HomePageMenu.vue";
import MobileToolbar from "@/components/Toolbars/MobileMenu.vue";
import Hero from "@/components/Home/Hero2.vue";
import Footer from "@/components/Home/Footer.vue";
import Compliant from "@/components/Home/Compliant.vue";
import Plans from "@/components/Home/Plans.vue";
import Description from "@/components/Home/Description.vue";
import InfoBanner from "@/components/Home/InfoBanner.vue";
import FAQ from '../components/Home/FAQ.vue';
import DownloadApp from "@/components/Home/DownloadApp.vue";

export default {
  name: "Home",
  components: {
    Hero,
    InfoBanner,
    Toolbar,
    MobileToolbar,
    Description,
    Plans,
    Compliant,
    Footer,
    DownloadApp,
    FAQ,
  },
};
</script>

