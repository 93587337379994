<template>
  <div class="mobile_nav_bar">
    <div class="mobile_nav_bar_title ml-10 d-flex">
      <img class="logo" src="img/static/logo.svg" alt="logo" />
      <div class="mobile_name center">
        <div>secur</div>
        <div class="bold">Meet</div>
      </div>
    </div>
    <div class="mobile_nav_bar_hamburger mr-15">
      <img  class="green_svg" src="img/icons/menu.svg" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
  },
};
</script>

<style scoped lang="less">
* {
  transition: height ease 0.2s;
}

.mobile_nav_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 60px;
  width: 100%;
  z-index: 2;
  background-color: var(--d2-blue);
  color: var(--white);
  height: 0px;
}

.logo {
  max-height: 43px;
  width: 40px;
}

.mobile_name {
  display: flex;
  width: 114px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 300;
  color: var(--green);
}

@media only screen and (min-width: 795px) {
  .mobile_nav_bar_title,
  .mobile_nav_bar_hamburger {
    visibility: hidden;
  }
}

@media only screen and (max-width: 794px) {
  .mobile_nav_bar {
    visibility: visible;
    height: 60px;
  }
}
</style>
